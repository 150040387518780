import React from "react";
import { FaRegCheckCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
function PendingApproval() {
  const navigate = useNavigate();
  const handleGoBackToHomepage = () => {
    navigate("/");
  };

  const handleGoBackToLogin = () => {
    navigate("/login");
  };

  return (
    <div className="bg-[#F9FAFB] py-14 h-screen">
      <div className="h-[30vh] px-10 max-md:px-5">
        <div className="flex justify-center items-center flex-col mx-auto mt-20">
          <div className="flex justify-center items-center flex-col max-w-[730px] border border-gray-200 p-6 rounded-lg bg-white">
            <div className="flex items-center justify-center gap-3 max-md:flex-col max-md:text-center">
              <FaRegCheckCircle className="text-[36px] text-devRegDarkBlue" />
              <div className="flex-1 text-[30px] font-bold text-[#1c3775] max-md:text-[24px]">
                Pending Approval
              </div>
            </div>
            <div className="mt-4 text-center font-normal max-md:text-sm">
              You have successfully submitted your interview schedule. Please
              wait for an admin to review and approve your registration. Once
              approved, you will be verified and you will receive further
              instructions via email.
            </div>
            <div className="w-full m-auto">
              <button
                onClick={handleGoBackToHomepage}
                className="mt-7 w-full m-auto bg-[#1c3775] text-white px-7 py-2 font-normal rounded-full text-lg max-md:text-[14px]"
              >
                Back to Homepage
              </button>
              <button
                onClick={handleGoBackToLogin}
                className="underline mt-3 w-full m-auto text-gray-700 font-normal text-lg max-md:text-[14px]"
              >
                Back to Login
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PendingApproval;
